import React, { useContext } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from "prop-types";
import calcVehicleRangeForBudget from "../../functions/vehicle/calcVehicleRangeForBudget";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormatAsCents, FormatCarName } from "../../utils/Helpers/Format";

import { Bar as HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "../../utils/chartSetup";

const FUEL_BUDGET_IN_DOLLARS = 5;

const FuelRangeChart = ({ car, forceUserPrefsPresets, hasExtraPadding }) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();

  if (!car) return null;
  const equivalentGasVehicle = car.equivalent_gas_vehicle || {};

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const vehicleRange = Math.round(
    calcVehicleRangeForBudget(
      car,
      FUEL_BUDGET_IN_DOLLARS,
      get("electricMilesPortionForPhev")
    )
  );
  const equivalentGasVehicleRange = Math.round(
    calcVehicleRangeForBudget(equivalentGasVehicle, FUEL_BUDGET_IN_DOLLARS)
  );

  const title = (
    <p className="h3 my-3">
      <FormattedMessage 
      id="homepage.fuelRangeChart.title"
      defaultMessage="How far can you drive the {car} on a {budget} fuel budget?"
      description="Fuel Range Chart Title"
      values= {{
        car: FormatCarName(car),
        budget: <strong style={{ fontWeight: "800" }}>{FormatAsCents(FUEL_BUDGET_IN_DOLLARS)}</strong>,
      }}
      />
    </p>
  );


  const chargedAtHome = intl.formatMessage({ id: "graph.fuelRange.chart.chargedHome", defaultMessage: "charged at home"});
  const similarGasVehicle = intl.formatMessage({ id: "graph.fuelRange.chart.similarGas", defaultMessage: "a similar Gas Vehicle"});

  const carNames = [
    [FormatCarName(car), chargedAtHome],
    [similarGasVehicle, FormatCarName(car.equivalent_gas_vehicle)]
  ];

  const chartData = {
    labels: carNames,
    datasets: [
      {
        backgroundColor: ["#5ca4d1", "#708090"],
        data: [
          vehicleRange,
          equivalentGasVehicleRange
        ]
      }
    ]
  };

  const chartOptions = {
    indexAxis: "y",
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          max: Math.round((vehicleRange * 1.7) / 20) * 20,
          stepSize: 20,
        },
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            weight: 400,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (value, ctx) => {
          return (
            value +
            " " +
            intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })
          );
        },
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700,
        },
      },
    },
  };

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { paddfing: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={260}
            type="horizontalBar"
            options={chartOptions}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
      <ShowHideButton buttonText={` ${intl.formatMessage({ id: "graph.graphValues", defaultMessage: "Graph Values"})}`} displayChildrenOnlyOnTablet>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col"><FormattedMessage
                      id="graph.fuelRange.chart.description"
                      defaultMessage="Description"
                      description="Description Title"
                    /></th>
                  <th scope="col">
                    <FormattedMessage
                      id="graph.fuelRange.chart.rangeOn"
                      defaultMessage="Range on"
                      description="Range on"
                    /> {FormatAsCents(FUEL_BUDGET_IN_DOLLARS)} {" "}
                    <FormattedMessage
                      id="graph.fuelRange.chart.fuelBudget"
                      defaultMessage="fuel budget"
                      description="fuel budget"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{FormatCarName(car)} <FormattedMessage
                      id="graph.fuelRange.chart.chargedHome"
                      defaultMessage="charged at home"
                      description="charged at home"
                    /></th>

                  <td>{vehicleRange} miles</td>
                </tr>
                <tr>
                  <th scope="row">
                    {FormatCarName(car.equivalent_gas_vehicle)} (<FormattedMessage
                      id="graph.fuelRange.chart.similarGas"
                      defaultMessage="a similar Gas Vehicle"
                      description="a similar Gas Vehicle"
                    />)
                  </th>
                  <td>{equivalentGasVehicleRange} <FormattedMessage
                        id="graph.fuelRange.chart.miles"
                        defaultMessage="miles"
                        description="miles"
                      /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

export default FuelRangeChart;

FuelRangeChart.propTypes = {
  car: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  hasExtraPadding: PropTypes.bool
};
